import { render, staticRenderFns } from "./PermissionBase.vue?vue&type=template&id=086a8ab0"
import script from "./PermissionBase.vue?vue&type=script&lang=ts&setup=true"
export * from "./PermissionBase.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports